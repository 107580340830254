import fetch from '../utils/fetchMapi'

export function getBcirclesList(query) {
  return fetch({
    url: '/admin/bcircle/get_bcircles',
    method: 'get',
    params: query
  })
}

export function addBcircles(query) {
  return fetch({
    url: '/admin/bcircle/create_update',
    method: 'post',
    params: query
  })
}

export function delBcircles(query) {
  return fetch({
    url: '/admin/bcircle/del',
    method: 'post',
    params: query
  })
}
