import fetch from '../utils/fetchMapi'

export function getActUnitList () {
  return fetch({
    url: '/admin/lottery/act_unit_list',
    method: 'get'
  })
}

export function actAdd (params = {}) {
  return fetch({
    url: '/admin/lottery/act_add',
    method: 'post',
    params
  })
}

export function actList (params = {}) {
  return fetch({
    url: '/admin/lottery/act_list',
    method: 'get',
    params
  })
}

export function actEdit (params = {}) {
  return fetch({
    url: '/admin/lottery/act_edit',
    method: 'post',
    params
  })
}

// 抽奖活动-添加奖品
export function addPrize(params = {}) {
    return fetch({
        url: '/admin/lottery/prize_add',
        method: 'post',
        params
    })
}

// 抽奖活动-奖品列表
export function prizeList(params = {}) {
    return fetch({
        url: '/admin/lottery/prize_list',
        method: 'get',
        params
    })
}

// 抽奖活动-奖品编辑
export function editPrize(params={}) {
    return fetch({
        url: '/admin/lottery/prize_edit',
        method: 'post',
        params
    })
}

// 抽奖活动-抽奖明细
export function orderList(params={}) {
    return fetch({
        url: '/admin/lottery/order_list',
        method: 'get',
        params
    })
}

export function getInletconfig(params={}) {
  return fetch({
    url: '/admin/lottery/inlet-config',
    method: 'get',
    params
  })
}
export function postInletconfig(params={}) {
  return fetch({
    url: '/admin/lottery/inlet-config',
    method: 'post',
    params
  })
}