import fetch from '../utils/fetchMapi'

export function getGasTradeList(query) {
  return fetch({
    url: '/admin/gas/order_list',
    method: 'get',
    params: query
  })
}
export function getPhoneTradeList(query) {
  return fetch({
    url: '/admin/phone/order_list',
    method: 'get',
    params: query
  })
}
export function getParkingTradeList(query) {
  return fetch({
    url: '/admin/parking/order_list',
    method: 'get',
    params: query
  })
}
export function getElectricityTradeList(query) {
  return fetch({
    url: '/admin/phone/order_list',
    method: 'get',
    params: query
  })
}
export function getWaterTradeList(query) {
  return fetch({
    url: '/admin/water/order_list',
    method: 'post',
    params: query
  })
}

export function getWaterOrderDetail(query) {
  return fetch({
    url: '/admin/water/order_detail',
    method: 'post',
    params: query
  })
}


export function RefundGasOrder(query){
  return fetch({
    url:'/admin/gas/refund',
    method:'post',
    params:query
  })
}

export function RefundPhoneOrder(query){
  return fetch({
    url:'/admin/phone/refund',
    method:'post',
    params:query
  })
}

export function RefundParkOrder(query){
  return fetch({
    url:'/admin/parking/refund',
    method:'post',
    params:query
  })
}

// 获取退款记录订单
export function RefundOrder(query){
  return fetch({
    url:'/admin/refund/list',
    method:"get",
    params:query
  })
}
