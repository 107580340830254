import fetch from '../utils/fetchMapi'

export const getFeedback = (query) =>
  fetch({
    url: '/admin/feedback/get_feedbacks',
    method: 'get',
    params: query
  })

export const delFeedback = (data) =>
  fetch({
    url: '/admin/feedback/del',
    method: 'post',
    params: data
  })
