import fetch from '../utils/fetchMapi';

export function getListTransactionOrder (query) {
  return fetch({
    url: '/trade/list-order',
    method: 'get',
    params: query
  })
}

export function getPaymentTypeList (query) {
  return fetch({
    url: '/trade/payment-type-list',
    method: 'get',
    params: query
  })
}

export function getExport(query) {
  return fetch({
    url: '/trade/list-order-export',
    method: 'get',
    params: query
  })
}

export function downFile(data) {
  const url = genUrl(data.csv_data, {})
  const a = document.createElement('a')
  a.href = url
  a.download = data.file_name
  a.click()
  window.URL.revokeObjectURL(url)
}

function genUrl(encoded, options) {
  const dataBlob = new Blob([`\ufeff${encoded}`], { type: 'text/plain;charset=utf-8' }) //返回的格式
  return window.URL.createObjectURL(dataBlob)
}

export function merchantList(query) {
  return fetch({
    url: '/trade/merchant-list',
    method: 'get',
    params: query
  })
}