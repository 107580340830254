import fetch from '../utils/fetchMapi'


// 获取商品分类列表
export function getGoodsGoryList() {
  return fetch({
    url: '/admin/goods/goods_type_list',
    method: 'get'
  })
}


// 添加商品分类列表
export function addGoodsGoryList(query){
  return fetch({
    url:'/admin/goods/goods_type_add',
    method:'post',
    params:query
  })
}


// 编辑商品分类列表
export function editGoodsGoryList(query){
return fetch({
  url:'/admin/goods/goods_type_edit',
  method:'post',
  params:query
})
}

// 删除商品列表
export function delGoodsGoryList(query){
  return fetch({
    url:'/admin/goods/goods_del',
    method:'post',
    params:query
  })
}


// 获取商品列表
export function getGoodsList(query) {
  return fetch({
    url: '/admin/goods/goods_list',
    method: 'get',
    params: query
  })
}

// 添加商品
export function addGoods(query) {
  return fetch({
    url: '/admin/goods/goods_add',
    method: 'post',
    params: query
  })
}

// 编辑商品
export function editGoods(data){
return fetch({
  url:'/admin/goods/goods_edit',
  method:'post',
  params:data
})
}

// 获取商品标签列表
export function getGoodsTag() {
  return fetch({
    url: '/admin/goods/goods_tag_list',
    method: 'get'
  })
}

// 添加商品标签列表
export function addGoodsTag(data) {
  return fetch({
    url: '/admin/goods/goods_tag_add',
    method: 'post',
    params: data
  })
}

// 编辑商品列表
export function editGoodsTag(data) {
  return fetch({
    url: '/admin/goods/goods_tag_edit',
    method: 'post',
    params: data
  })
}

// 获取订单列表
export function getOrder(){
  return fetch({
    url:'/admin/order/order_list',
    method:'get'
  })
}


