import fetch from '../utils/fetch'

export function getOrders (query) {
  return fetch({
    url: '/ziti-orders',
    method: 'get',
    params: query
  })
}

export function getManagement (query) {
  return fetch({
    url: '/account/management',
    method: 'get',
    params: query
  })
}