import fetch from '../utils/fetchMapi'


// 交易明细
export const dealDetail = (data) =>fetch({url:'/admin/lkl/trade_record',method:'get',params:data})
// 分账明细
export const spiltDetail = (data) =>fetch({url:'/admin/lkl/split_record',method:'get',params:data})
// 分账手续费
export const dealFee = (data) =>fetch({url:'/admin/lkl/fee_record',method:'get',params:data})
// 通道手续费
export const appFee = (data) =>fetch({url:'/admin/lkl/app_fee_record',method:'get',params:data})
// 结算明细
export const moneyDetail = (data) =>fetch({url:'/admin/lkl/settle_record',method:'get',params:data})
