import fetch from '../utils/fetchMapi'


// 添加保险机构
export const AddInstitution = (data) =>fetch({url:'/admin/institution/add',method:'post',params:data})

// 编辑保险机构
export const EditInstitution = (data) =>fetch({url:'/admin/institution/edit',method:'post',params:data})

// 删除保险机构
export const delInstitution = (data) =>fetch({url:'/admin/institution/delete',method:'post',params:data})

// 获取保险机构
export const getInstitution = (data) =>fetch({url:'/admin/institution/list',method:'get',params:data})

// 添加保险产品
export const addInsurance = (data) =>fetch({url:'/admin/insurance/add',method:'post',params:data})

// 编辑保险产品
export const EditInsurance = (data) =>fetch({url:'/admin/insurance/edit',method:'post',params:data})

// 删除保险产品
export const DelInsurance = (data) =>fetch({url:'/admin/insurance/delete',method:'post',params:data})

// 保险产品上下架
export const ShelvesInsurance = (data) =>fetch({url:'/admin/insurance/freight-on-shelf',method:'post',params:data})

// 获取保险产品列表
export const getInsurance = (params) =>fetch({url:'/admin/insurance/list',method:'get',params})

// 获取保险订单列表
export const getInsuranceOrder = (params) =>fetch({url:'/admin/insurance/order/list',method:'get',params})

// 获取保险订单详情
export const getInsuraceOrderDetail = (params)=>fetch({url:'/admin/insurance/order/detail',method:'get',params})

// 保险订单添加报价
export const InsuranceAddConsult = (data) =>fetch({url:'/admin/insurance/order/add-consult',method:'post',params:data})

// 保险订单添加保单
export const InsuranceAddWarranty = (data) =>fetch({url:'/admin/insurance/order/add-warranty',method:'post',params:data})

// 保险订单修改参保人的信息
export const InsuranceOrderChangeUserInfo = (data) =>fetch({url:'/admin/insurance/order/change-user-info',method:'post',params:data})

// 保险产品关联机构
export const InsuranceProductConnectInstituion = (data) =>fetch({url:'/admin/insurance/add-insurance-institution',method:'post',params:data})

// 修改保险公司报价方案
export const EditInsuranceInstitution = (data) =>fetch({url:'/admin/insurance/edit-insurance-institution',method:'post',params:data})

// 删除保险公司报价方案
export const DelInsuranceInstitution = (data) =>fetch({url:'/admin/insurance/delete-insurance-institution',method:'post',params:data})