import fetch from '../utils/fetchMapi'
//以下为banner
export function saveBanner (query) {
  return fetch({
    url: '/admin/banner/create_update',
    method: 'post',
    params: query
  })
}

export function getBannerList (query) {
  return fetch({
    url: '/admin/banner/get_banners',
    method: 'get',
    params: query
  })
}

export function deleteBanner (id) {
  return fetch({
    url: '/admin/banner/del?id=' + id,
    method: 'post'
  })
}

export function changeStatus (query) {
  return fetch({
    url: '/admin/banner/is_show',
    method: 'post',
    params: query
  })
}




//以下为公告
export function saveNotice (query) {
  console.log(query);
  return fetch({
    url: '/admin/notice/create_update',
    method: 'post',
    params: query
  })
}

export function getNoticeList (query) {
  return fetch({
    url: '/admin/notice/notice_list',
    method: 'get',
    params: query
  })
}
export function deleteNotice (id) {
  return fetch({
    url: '/admin/notice/del?id=' + id,
    method: 'post'
  })
}

export function getCategoryList (query) {
  return fetch({
    url: '/admin/fpc/get_fpc_category',
    method: 'get',
    params: query
  })
}

export function changeNoticeStatus (query) {
  return fetch({
    url: '/admin/notice/is_show',
    method: 'post',
    params: query
  })
}

export function setSetting (query) {
  return fetch({
    url: '/admin/water/set_setting',
    method: 'post',
    params: query
  })
}

export function getSetting () {
  return fetch({
    url: '/admin/water/get_setting',
    method: 'get',
  })
}
