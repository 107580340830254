import fetch from '../utils/fetchMapi';

export function postRuleSetting (query) {
  return fetch({
    url: '/admin/point/rule-setting',
    method: 'POST',
    params: query
  })
}

export function postNoticeSetting (query) {
  return fetch({
    url: '/admin/point/notice-setting',
    method: 'POST',
    params: query
  })
}

export function getRuleAndNotice (query) {
  return fetch({
    url: '/admin/point/rule-and-notice',
    method: 'GET',
    params: query
  })
}