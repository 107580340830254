// 保险路由
const name ="保险"
import Layout from '@/view/layout'    //主框架


export default{
    path:'/order',
    component:Layout,
    children:[
        {
            path:'insurance/insuranceOrder',
            name:'订单管理',
            component:()=>import ('@/view/mall/insurance/order/list'),
        },
        {
            path:'insurance/productsManagement',
            name:'产品管理',
            component: () => import('@/view/mall/insurance/product/list')
        },
        {
            path:'insurance/organisationsManagement',
            name:'机构管理',
            component: () => import('@/view/mall/insurance/organization/list')
        }
    ]
}